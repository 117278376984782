import { defineComponent, reactive, toRefs, computed } from "vue";
export default defineComponent({
  name: "Deposit",

  setup() {
    const state = reactive({
      usdtInput: 0,
      ratio: 92.5,
      bonusRate: 0.02 // 奖励比率，2% 的 USDT 输入值

    }); // 计算奖励和接收金额

    const calculatedBonus = computed(() => (state.usdtInput * state.bonusRate).toFixed(2));
    const calculatedReceive = computed(() => (state.usdtInput * state.ratio).toFixed(2));
    return { ...toRefs(state),
      calculatedBonus,
      calculatedReceive
    };
  }

});